<template>
  <div class="medical container">

    <Head></Head>
    <main>
      <div class="banner" style="background: #aa55ff;">
        <div class="wrap">
          <div class="txt">
             <h1>CCRS系统</h1>
              <div class="subtitle">基于AI和大数据的心内科科研平台</div>
              <div>· 高扩展性数据库彻底满足冠脉PCI和结构性心脏病手术要求，可与心内科导管室工作站无缝集成。</div>

              <div>· 手术步骤自动生成，统一规范术者操作。</div>

              <div>· 手术器械全流程管理。完美解决录入信息不准确的问题。</div>
              <div>· 手术报告内容一键自动生成。大幅减少医生工作量。</div>
          </div>
          <div class="img">
            <img src="../assets/Class.svg">
          </div>
        </div>
      </div>
      <ContBolck v-for="(item,index) in cont.records" :key="index" :title="item.title" :sub-title="item.desc">
        <div style="text-align:center">
          <img :src="item.src" alt="">
        </div>
      </ContBolck>
    </main>
    <Foot></Foot>
  </div>
</template>

<script>
  import ContBolck from '../components/contBolck'
  import Foot from '../components/foot'
  import Head from '../components/head'
  export default {
    components: {
      ContBolck,
      Foot,
      Head
    },
    data() {
      return {
        cont: {
          records: [{
            title: '基于AI和大数据的心内科科研平台',
            desc: '',
            src: require('../assets/me01.jpg')
          }]
        }
      }
    }
  }
</script>


<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    main {
      flex: 1;

      .banner {

        background: #2979FF;

        .wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 520px;
          line-height: 36px;
          color: #FFF;

          h1 {
            font-size: 36px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 20px;
          }

          .img {
            width: 35%;
            overflow: hidden;
            height: 100%;
            display: flex;
            align-items: flex-end;

            img {
              width: 100%;
            }
          }

        }
      }
    }
  }
</style>